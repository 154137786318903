import { lazy } from "react";
import { Navigate, Route } from "react-router-dom";
// import EditPost from "./EditPost/EditPost";
import ViewPost from "./components/ViewPost/ViewPost";
import { CustomRoutes, RestrictedRoute } from "src/components";

const EditPost = lazy(() =>
  import(/* webpackChunkName: 'EditPost' */ "./EditPost/EditPost")
);

function Post(props) {
  // if (!post?.body) return <></>;

  return (
    <CustomRoutes>
      // Navigate to next path
      <Route index element={<Navigate replace to={" /"} />} />
      <Route path=":currentTitleDashes">
        <Route index element={<ViewPost />} />
        <Route
          path="editpost"
          element={
            <RestrictedRoute action="ShortPostToolbar--editPost">
              <EditPost />
            </RestrictedRoute>
          }
        />
        {/* <Route path="*" element={<></>} /> */}
      </Route>
      {/* <Route path="*" element={<Navigate replace to={`/${fixedURL}`} />} /> */}
      {/* <Route path="*" element={<Navigate replace to={post.title} />} /> */}
    </CustomRoutes>
  );
}

export default Post;
