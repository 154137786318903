import { Routes } from "react-router-dom";
import { useCustomLocation } from "src/hooks";

function CustomRoutes({ children, ...rest }) {
  let location = useCustomLocation();
  let state = location.state;

  return (
    <Routes location={state?.backgroundLocation || location} {...rest}>
      {children}
    </Routes>
  );
}

export default CustomRoutes;
